export const DEVICE_PC = 'pc';
export const DEVICE_SP = 'sp';
export const DEVICE_TABLET = 'tablet';

export const deviceMatches = (target: string, callback: Function) => {
  const uaCategory: string = getUaCategory();
  // UAだけで判定できるなら終了
  if (target === uaCategory) {
    callback(true);
    return;
  }
  if (target === DEVICE_SP) {
    // 不明UA (or マイナースマートフォン) への対応。
    if (uaCategory === DEVICE_PC && isTouchDevice()) {
      detectWebCam(callback);
      return;
    }
  }
  callback(false);
}

function getUaCategory (): string {
  const ua = navigator.userAgent
  if (ua.includes('Mobile') && (ua.includes('iPhone') || ua.includes('Android'))) {
    return DEVICE_SP;
  } else if (ua.includes('iPad') || ua.includes('Android')) {
    return DEVICE_TABLET;
  }
  return DEVICE_PC;
}

function isTouchDevice (): boolean {
  return window.ontouchstart === null;
}

function detectWebCam (callback: Function) {
  const md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then(devices => {
    const detect = devices.find(device => 'videoinput' === device.kind);
    callback(detect);
  });
}

function isIPhone (userAgent: string) {
  return userAgent.includes("iPhone");
}

function isAndroid (userAgent: string) {
  return userAgent.includes("Android");
}

function getAndroidOSVersion (userAgent: string) {
  const matches = /Android (\d+)/.exec(userAgent);
  return matches ? Number(matches[1]) : 0;
}

function getIPhoneOSVersion (userAgent: string) {
   const matches = /iPhone OS (\d+)/.exec(userAgent);
  return matches ? Number(matches[1]) : 0;
}

function isAndroidChrome (userAgent: string) {
  return /Gecko\) (Version\/[^ ]+ )?Chrome\/[^ ]+ Mobile Safari\/[^ ]+$/.test(userAgent);
}

function isIPhoneSafari (userAgent: string) {
  return /Gecko\) (Version\/[^ ]+ )?Mobile\/[^ ]+( Safari\/[^ ]+)?$/.test(userAgent);
}

function getDeviceName (userAgent: string) {
  if (isAndroid(userAgent)) {
    const matches = userAgent.match(/Linux; Android(.*?); (.+?)\) AppleWebKit/);
    if (matches) {
      const tmpDeviceName = matches[2]
      const matchesWithBuildNo = tmpDeviceName.match(/(.+) Build\//);
      return matchesWithBuildNo ? matchesWithBuildNo[1] : matches[2];
    }
    return 'Android'
  } else if (isIPhone(userAgent)) {
    return 'iPhone'
  }
  return '';
}

// OS要件（iOS:13以上, Android:9以上 はOK）
type OSRequirement = {
  version: number,
  versionParser: (userAgent: string) => number,
}

export class DeviceCondition {
  isIPhoneOS = false;
  isAndroidOS = false;
  isSupportedOS = false;
  isSupportedBlowser = false;
  isWhiteoutDevice = false;
  defaultBlowser = '';
  deviceName = '';

  constructor(userAgent: string) {
    this.isIPhoneOS = isIPhone(userAgent);
    this.isAndroidOS = isAndroid(userAgent);
    this.isSupportedOS = this.evaluateIfSupportedOS(userAgent)
    this.isSupportedBlowser = this.evaluateIfSupportBlowser(userAgent);
    this.isWhiteoutDevice = this.evaluateIfWhiteoutDevice(userAgent);
    this.defaultBlowser = this.isIPhoneOS ? 'Safari' : 'Chrome';
    this.deviceName = getDeviceName(userAgent);
  }

  evaluateIfSupportedOS(userAgent: string) {
    // 対応OS要件
    const osRequirement: OSRequirement = this.isIPhoneOS ? {
      version: 13,
      versionParser: getIPhoneOSVersion,
    } : this.isAndroidOS ? {
      version: 9,
      versionParser: getAndroidOSVersion
    } : {
      // 判定できないものは常にアラートを出したい
      version: 1,
      versionParser: () => 0
    };

    return osRequirement.versionParser(userAgent) >= osRequirement.version;
  }

  evaluateIfSupportBlowser(userAgent: string) {
    const evaluate = this.isIPhoneOS ? isIPhoneSafari
      : this.isAndroidOS ? isAndroidChrome
      // 判定できないものは常にアラートを出したい
      : () => false;
    return evaluate(userAgent)
  }

  /**
   * [リアカメラ写真の白飛び問題対応] 白飛び問題が発生する可能性がある端末かどうか.
   * iOS15.4, iOS15.4.1 の一部の端末で白飛びを確認している.
   * iOS15.4以降の場合は(iOSの修正によって直る可能性があるが念の為)trueを返す.
   * iOS15.3以前もしくはAndroidの場合はfalseを返す.
   */
  evaluateIfWhiteoutDevice (userAgent: string): boolean {
    const majorIosVersion = getIPhoneOSVersion(userAgent);
    if (majorIosVersion >= 16) {
      // iOS16以降の場合はtrue
      return true;
    } else if (majorIosVersion === 15) {
      // iOS15の場合
      const matches = /iPhone OS (\d+)_(\d+)/.exec(userAgent);
      if (matches) {
        // マイナーバージョンが4以降の場合はtrue
        const minorIosVersion = Number(matches[2]);
        return minorIosVersion >= 4;
      }
    }
    return false;
  }
}
