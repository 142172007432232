import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { ISession } from '@/types/session';

@Module({ namespaced: true })
export default class Session extends VuexModule {
  _session: ISession = {};

  @Mutation
  reset() {
    this._session = {};
  }

  @Mutation
  _set(value: ISession) {
    this._session = value;
  }

  @Mutation
  increseMailConCodeSendCount() {
    this._session.mail_con_code_send_count = (this._session.mail_con_code_send_count || 0) + 1;
  }

  @Mutation
  incresePhoneConCodeSendCount() {
    this._session.phone_con_code_send_count = (this._session.phone_con_code_send_count || 0) + 1;
  }

  /**
   * セッション情報取得
   *
   * @readonly
   * @type {(ISession | null)}
   * @memberof Session
   */
  get session(): ISession | null {
    return this._session;
  }
}
