import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import { InputAttributes } from '@/types/InputAttributes';

interface AttributesInputItemsGetResponse {
  input_name: boolean
  input_name_kana: boolean
  input_gender: boolean
  input_birth_date: boolean
  input_address: boolean
  input_document: boolean
}

@Module({ namespaced: true })
export default class AttributeInputItems extends VuexModule {
  private initialized = false;
  private inputName = true;
  private inputNameKana = true;
  private inputGender = true;
  private inputBirthDate = true;
  private inputAddress = true;
  private inputDocument = true;

  @Mutation
  reset() {
    this.initialized = false;
    this.inputName = true;
    this.inputNameKana = true;
    this.inputGender = true;
    this.inputBirthDate = true;
    this.inputAddress = true;
    this.inputDocument = true;
  }

  @Mutation
  private set(data: AttributesInputItemsGetResponse) {
    this.inputName = data.input_name;
    this.inputNameKana = data.input_name_kana;
    this.inputGender = data.input_gender;
    this.inputBirthDate = data.input_birth_date;
    this.inputAddress = data.input_address;
    this.inputDocument = data.input_document;
    this.initialized = true;
  }

  @Action({ rawError: true })
  async load() {
    return new Promise<void>((resolve, reject): void => {
      axios.get(API_DOMAIN + '/v1/attributes/inputItems')
        .then((response: any) => {
          this.context.commit('set', response.data);
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        })
    })
  }

  get isInitialized() {
    return this.initialized;
  }

  get data (): InputAttributes {
    return {
      name: this.inputName,
      nameKana: this.inputNameKana,
      gender: this.inputGender,
      birthDate: this.inputBirthDate,
      address: this.inputAddress,
      document: this.inputDocument,
    };
  }
}
