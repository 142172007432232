import { RouteRecordRaw } from 'vue-router';

/**
 * 企業関連のルーティング設定
 */
export default [
  {
    path: 'company',
    name: 'company.index',
    meta: {
      close_name: 'top'
    },
    components: {
      upper: () => import('@/views/upper/Companies.vue'),
      lower: () => import('@/components/EmptyLower.vue')
    }
  },
  {
    path: 'company/optout/:id/confirm',
    name: 'company.optout.confirm',
    meta: {
      close_name: 'company.index',
      next: 'company.optout.complete',
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/CompanyOptoutConfirm.vue')
    }
  },
  {
    path: 'company/optout/:id/complete',
    name: 'company.optout.complete',
    meta: {
      close_name: 'company.index'
    },
    components: {
      upper: () => import('@/views/upper/CompanyOptoutComplete.vue'),
      lower: () => import('@/components/EmptyLower.vue')
    }
  },
] as RouteRecordRaw[];
