import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import { IAttributes } from '@/types/attributes';

@Module({ namespaced: true })
export default class AttributeInitialValue extends VuexModule {
  _attributes: IAttributes = {};

  @Mutation
  reset() {
    this._attributes = {};
  }

  @Mutation
  _set(value: IAttributes) {
    this._attributes = value;
  }

  /**
   * APIから会員属性初期値を取得
   *
   * @returns {Promise<boolean>}
   * @memberof User
   */
  @Action({ rawError: true })
  async load(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .get(API_DOMAIN + '/v1/attributes/initialValue')
        .then((response: any) => {
          const data: IAttributes = response.data as IAttributes;
          this.context.commit('_set', data);
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * 会員属性初期値取得
   *
   * @readonly
   * @type {(IAttributes | null)}
   * @memberof User
   */
  get attributes(): IAttributes | null {
    return this._attributes;
  }
}
