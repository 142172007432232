import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AuthRoutes from './auth_routes';
import AuthedRoutes from './authed_routes';
import PhotoRoutes from './photo';
import AttrRoutes from './attr';
import CompanyRoutes from './company';
import UnsubscribeRoutes from './unsubscribe';
import FaceAuth from './face_auth';

declare module 'vue-router' {
  interface RouteMeta {
    action?: string;
    allow_pc?: boolean;
    back?: string;
    close_name?: string;
    closeModificationsInput?: string;
    closeModificationsPhoto?: string;
    disable_pseudo_scrollbar?: boolean;
    enableBack?: boolean;
    from?: {
      name?: string;
      redirect?: string;
    };
    hidden_header_logo?: boolean;
    next?: string | RouteMetaNext;
    next_mynumber?: string | RouteMetaNext;
    public_path?: boolean;
    retake?: string;
    showCompanyLogo?: boolean;
    video?: {
      facingMode: 'user' | 'environment';
    }
  }

  interface RouteMetaNext {
    GREEN?: string;
    SILVER?: string;
    GOLD?: string;
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/:lang?',
    component: () => import('@/views/App.vue'),
    children: [
      {
        path: 'status',
        name: 'status',
        meta: {
          hidden_header_logo: true,
          public_path: true,
          allow_pc: true
        },
        components: {
          upper: () => import('@/views/upper/Status.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: 'common/static/error/:errorCode',
        name: 'error',
        meta: {
          hidden_header_logo: true,
          public_path: true,
          allow_pc: true
        },
        components: {
          upper: () => import('@/views/upper/Error.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: 'common/static/maintenance',
        name: 'maintenance',
        meta: {
          hidden_header_logo: true,
          public_path: true
        },
        components: {
          upper: () => import('@/views/upper/Maintenance.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      ...AuthRoutes,
      ...AuthedRoutes,
      ...PhotoRoutes,
      ...AttrRoutes,
      ...CompanyRoutes,
      ...UnsubscribeRoutes,
      ...FaceAuth,
      {
        path: 'top',
        name: 'top',
        components: {
          upper: () => import('@/views/upper/Top.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: 'mypage/terms',
        name: 'mypage.terms',
        meta: {
          hidden_header_logo: true,
          public_path: false,
          disable_pseudo_scrollbar: true
        },
        components: {
          upper: () => import('@/components/MypageRegisterModal.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: 'notify/:id',
        name: 'notify',
        meta: {
          close_name: 'top'
        },
        components: {
          upper: () => import('@/views/upper/Notify.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: 'lp',
        name: 'lp',
        meta: {
          hidden_header_logo: true,
          public_path: true
        },
        components: {
          upper: () => import('@/views/upper/Lp.vue'),
          lower: () => import('@/views/lower/Lp.vue')
        }
      },
      {
        path: 'terms',
        name: 'terms',
        meta: {
          hidden_header_logo: true,
          public_path: false,
          disable_pseudo_scrollbar: true
        },
        components: {
          upper: () => import('@/components/RegisterModal.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: 'mypage/loginHistories',
        name: 'mypage.loginHistories',
        meta: {
          close_name: 'top'
        },
        components: {
          upper: () => import('@/views/upper/LoginHistories.vue'),
          lower: () => import('@/components/Empty.vue')
        }
      },
      {
        path: '',
        name: 'index',
        meta: {
          hidden_header_logo: true,
          public_path: false,
          showCompanyLogo: true
        },
        components: {
          upper: () => import('@/views/upper/Index.vue'),
          lower: () => import('@/views/lower/Index.vue')
        }
      }
    ]
  },
  {
    path: '/:lang?/:pathMatch(.*)*',
    component: () => import('@/views/404.vue')
  }
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return { left: 0, top: 0 }
  }
});
