<template>
  <div class="app">
    <div class="app__body">
      <transition name="fade" @afterLeave="is_show = true">
        <div class="splash" v-if="is_progress">
          <img src="@/assets/img/splash.svg" width="70" height="105" alt="" />
        </div>
      </transition>
      <router-view :class="{ hidden: !is_show }" v-if="is_ready" v-slot="{ Component }">
        <transition>
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <transition name="fade">
      <div v-if="displayLoading" class="overlay">
        <Loader />
      </div>
    </transition>
    <!-- 疑似スクロールバー（スクロールバーが表示されないとスクロール可能であることに気づかない利用者が居る為） -->
    <div class="pseudo-scrollbar" v-show="showScrollBar && !route.meta.disable_pseudo_scrollbar"
      :style="{ height: scrollBarHeight }"></div>
  </div>
</template>

<script lang="ts">
import { Options, Watch, Vue } from 'vue-property-decorator';
import { useRoute, RouteLocationNormalizedGeneric } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '@/store';
import { handleError } from '@/plugins/ApiRequest';
import { experimentLoaded } from '@/plugins/analytics';
import Loader from '@/components/parts/Loader.vue';
import { DEVICE_SP, deviceMatches } from "@/plugins/devices";
import { pageView } from '@/plugins/analytics';
import { APP_VERSION, GA_TRACKING_ID } from '@/plugins/getenv';

@Options({
  components: {
    Loader
  }
})
export default class App extends Vue {
  store = useStore(key);
  route = useRoute();

  // 準備フラグ
  is_ready = false;

  // 読み込み中フラグ
  // router-view のナビゲーションが確立したら、読み込み中フラグをオフにする
  is_progress = true;

  // 画面表示フラグ
  // 読み込み中アニメーションがフェードアウトしたら、ビューを表示する
  is_show = false;

  // body の現在または直前の offsetHeight
  bodyHeight = 0;

  // 疑似スクロールバーの表示フラグ
  showScrollBar = false;

  // 疑似スクロールバーの高さ
  scrollBarHeight = '0%';

  // ローディング表示フラグ
  get displayLoading(): boolean {
    // API通信中フラグ
    const isProcessing: boolean = this.store.getters['Processing/isApiProcessing'];
    const currentRouteName = this.route.name as string || '';
    // 他にロード画面がでるrouteは除外
    const nonTargetRouteName: string[] = [
      // ← prettier で1行にさせないためのコメント
      '',
      'recovery.phone.photo.face',
      'register.photo.live',
      'register.photo.selfie',
      'register.photo.selfieBack',
      'register.photo.front',
      'register.photo.slope',
      'register.photo.back',
      'register.auth.photo.face',
      'registered.photo.live',
      'registered.photo.selfie',
      'registered.photo.selfieBack',
      'registered.photo.front',
      'registered.photo.slope',
      'registered.photo.back',
      'register.complete',
      'registered.complete',
      'register.store',
      'auth.mail.store',
      'mypage.auth.photo.face',
      'mypage.auth.mail.store',
      'recovery.phone.mail.store',
      'register.auth.store',
      'auth.phone.store',
      'mypage.auth.phone.store',
      'recovery.phone.new.store',
      'unsubscribe.code',
      'faceauth.mail.store',
      'faceauth.phone.store',
      'faceauth.match.face'
    ];
    return isProcessing && !nonTargetRouteName.includes(currentRouteName);
  }

  created() {
    this.store.dispatch('Globals/getGlobals')
      .catch((error: any) => {
        handleError(error);
      });

    // ※ 暫定対応
    // 撮影中にリロードした場合、撮影を低解像度で行う（旧iPhoneでクラッシュした際の対策）
    if (this.store.getters['HighResolution/photoStarted']) {
      this.store.commit('HighResolution/_setEnabled', false);
    }
  }

  mounted() {
    this.is_ready = true;

    deviceMatches.bind(this)(DEVICE_SP, (matched: boolean) => {
      if (!matched) return;
      // 疑似スクロールバー表示処理（スクロール可能なコンテンツ量であるかを監視する）
      setInterval(() => {
        if (document.body.offsetHeight != this.bodyHeight) {
          this.bodyHeight = document.body.offsetHeight;
          this.showScrollBar = window.innerHeight < this.bodyHeight;
          this.scrollBarHeight = (window.innerHeight / this.bodyHeight) * 100 + 'vh';
        }
      }, 500);

      window.addEventListener('scroll', () => this.showScrollBar = false);
    });

    // Google Optimizeとの待ち合わせ
    experimentLoaded.then(() => {
      this.is_progress = false;
    });
  }


  @Watch('route', { immediate: true, deep: true })
  onChangeRoute(to: RouteLocationNormalizedGeneric) {
    if (GA_TRACKING_ID && to.matched.length) {
      pageView(APP_VERSION, to.path)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/fade';
@import '@/assets/scss/math';
@import '@/assets/scss/mixins/color';
$loader_icon_num: 12;
$loader_icon_size: 10px;
$loader_icon_radian: calc(360 / $loader_icon_num / 180 * pi());
$loader_radius: 30px;

.app {
  position: relative;
  z-index: 1;
  background-color: #fff;
  min-height: 100%;

  &__body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  color: $basic-color;
  background: rgba($color: #fff, $alpha: 0.5);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    animation: load 1s forwards linear;

    @keyframes load {
      0% {
        opacity: 0;
      }

      70% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

.splash {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-size: 61.5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.pseudo-scrollbar {
  width: 4px;
  top: 4px;
  right: 4px;
  position: absolute;
  background-color: #aaa;
  border-radius: 4px;
  z-index: 10;
}
</style>
