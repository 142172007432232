import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import { IExpressAttributes } from '@/types/expressAttributes';
import { IAttributes } from '@/types/attributes';

@Module({ namespaced: true })
export default class Express extends VuexModule {
  /**
   * エクスプレスモード格納情報
   */
  _expressAttributes: IExpressAttributes = {};

  _loaded: boolean = false;

  /**
   * エクスプレスモード格納情報取得
   * @type {(IExpressAttribute | null})
   * @memberof Express
   */
  get expressAttributes(): IExpressAttributes | null {
    return this._expressAttributes;
  }

  /**
   * エクスプレスモードの値を取得済みか
   * @type { boolean }
   * @memberof Express
   */
  get loaded(): boolean {
    return this._loaded;
  }

  /**
   * エクスプレスモードの格納データをユーザー属性の型に変換して取得
   * @type {( IAttributes )}
   * @memberof Express
   */
  get attributes(): IAttributes {
    // 郵便番号
    let postalcode = undefined
    if (this._expressAttributes.postal1 && this._expressAttributes.postal2) {
      postalcode = this._expressAttributes.postal1 + this._expressAttributes.postal2
    }
    // 誕生日
    let birthdate = undefined
    if (this._expressAttributes.birth_year && this._expressAttributes.birth_month && this._expressAttributes.birth_date) {

      birthdate = `${this._expressAttributes.birth_year}-${this._expressAttributes.birth_month}-${this._expressAttributes.birth_date}`
    }

    let documentExpire

    // 本人確認書類の有効期限
    if (this._expressAttributes.document_expire_year
        && this._expressAttributes.document_expire_month
        && this._expressAttributes.document_expire_date) {
      documentExpire = `${this._expressAttributes.document_expire_year}-`
        + `${this._expressAttributes.document_expire_month}-`
        + `${this._expressAttributes.document_expire_date}`
    }

    // ユーザー属性の型に変換
    const attributes = {
      email: this._expressAttributes.email,
      pho_number: this._expressAttributes.phone_number,
      fam_name_kana: this._expressAttributes.family_name_kana,
      gender: this._expressAttributes.gender,
      city: this._expressAttributes.city,
      building: this._expressAttributes.building,
      street: this._expressAttributes.street,
      pref: this._expressAttributes.pref,
      fir_name: this._expressAttributes.first_name,
      fam_name: this._expressAttributes.family_name,
      fir_name_kana: this._expressAttributes.first_name_kana,
      pos_code: postalcode,
      bir_day: birthdate,
      doc_expire: documentExpire,
      doc_number: this._expressAttributes.document_number,
      doc_type: this._expressAttributes.document_type
    } as IAttributes;

    return attributes;
  }

  /**
   * Eメールアドレス取得
   * @type {( string | null )}
   * @memberof Express
   */
  get email(): string | undefined {
    return this._expressAttributes.email
  }

  /**
   * 携帯電話番号の取得
   * @type {( string | null )}
   * @memberof Express
   */
  get phoneNumber(): string | undefined {
    return this._expressAttributes.phone_number
  }

  @Mutation
  reset() {
    this._expressAttributes = {};
    this._loaded = false;
  }

  /**
   * エクスプレスモード格納データを更新
   */
  @Mutation
  _set(value: IExpressAttributes) {
    this._loaded = true;
    this._expressAttributes = value;
  }

  /**
   * APIからエクスプレスモード利用時の格納されたデータを取得
   * @returns { Promise<void> }
   * @memberof Express
   */
  @Action({ rawError: true })
  async load(): Promise<void> {
    if (this._loaded) {
      return;
    }
    await axios
      .get(API_DOMAIN + '/v1/express')
      .then((res: any) => {
        this.context.commit('_set', res.data)
      })
      .catch((err: any) => {
        throw err
      });
  }
}
