import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import { IAttributes } from '@/types/attributes';

@Module({ namespaced: true })
export default class User extends VuexModule {
  _attributes: IAttributes = {};

  @Mutation
  reset() {
    this._attributes = {};
  }

  @Mutation
  _set(value: IAttributes) {
    this._attributes = value;
  }

  /**
   * 規約同意
   *
   * @param {string} value
   * @memberof User
   */
  @Mutation
  setAgreedTermsId(value: number) {
    this._attributes.agreed_terms_id = value;
  }

  /**
   * プライバシーポリシー同意
   *
   * @param {string} value
   * @memberof User
   */
  @Mutation
  setAgreedPrivacyPolicyId(value: number) {
    this._attributes.agreed_privacy_policy_id = value;
  }

  /**
   * メールアドレス登録
   *
   * @param {string} value
   * @memberof User
   */
  @Mutation
  setEmail(value: string) {
    this._attributes.email = value;
  }

  /**
   * 携帯電話番号登録
   *
   * @param {string} value
   * @memberof User
   */
  @Mutation
  pho_number(value: string) {
    this._attributes.pho_number = value;
  }

  /**
   * APIからユーザー情報を取得
   *
   * @returns {Promise<void>}
   * @memberof User
   */
  @Action({ rawError: true })
  async load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(API_DOMAIN + '/v1/attributes')
        .then((response: any) => {
          const data: IAttributes = response.data as IAttributes;
          this.context.commit('_set', data);
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * ユーザー情報取得
   *
   * @readonly
   * @type {(IAttributes | null)}
   * @memberof User
   */
  get attributes(): IAttributes | null {
    return this._attributes;
  }

  /**
   * 会員ID
   *
   * @readonly
   * @type {(string | null)}
   * @memberof User
   */
  get men_id(): string | null {
    if (this._attributes !== null) {
      return this._attributes.mem_id || null;
    }
    return null;
  }

  /**
   * メールアドレス
   *
   * @readonly
   * @type {(string | null)}
   * @memberof User
   */
  get email(): string | null {
    if (this._attributes !== null) {
      return this._attributes.email || null;
    }
    return null;
  }
}
