import { createI18n, } from 'vue-i18n'
import en from '@/locales/en.json';
import enFields from '@/locales/en/fields.json';
import enModify from '@/locales/en/modify.json';
import enConfig from '@/locales/en/config.json';
import es from '@/locales/es.json';
import esFields from '@/locales/es/fields.json';
import esModify from '@/locales/es/modify.json';
import esConfig from '@/locales/es/config.json';
import id from '@/locales/id.json';
import idFields from '@/locales/id/fields.json';
import idModify from '@/locales/id/modify.json';
import idConfig from '@/locales/id/config.json';
import ja from '@/locales/ja.json';
import jaFields from '@/locales/ja/fields.json';
import jaModify from '@/locales/ja/modify.json';
import jaConfig from '@/locales/ja/config.json';
import my from '@/locales/my.json';
import myFields from '@/locales/my/fields.json';
import myModify from '@/locales/my/modify.json';
import myConfig from '@/locales/my/config.json';
import pt from '@/locales/pt.json';
import ptFields from '@/locales/pt/fields.json';
import ptModify from '@/locales/pt/modify.json';
import ptConfig from '@/locales/pt/config.json';
import th from '@/locales/th.json';
import thFields from '@/locales/th/fields.json';
import thModify from '@/locales/th/modify.json';
import thConfig from '@/locales/th/config.json';
import tl from '@/locales/tl.json';
import tlFields from '@/locales/tl/fields.json';
import tlModify from '@/locales/tl/modify.json';
import tlConfig from '@/locales/tl/config.json';
import vi from '@/locales/vi.json';
import viFields from '@/locales/vi/fields.json';
import viModify from '@/locales/vi/modify.json';
import viConfig from '@/locales/vi/config.json';
import zh from '@/locales/zh.json';
import zhFields from '@/locales/zh/fields.json';
import zhModify from '@/locales/zh/modify.json';
import zhConfig from '@/locales/zh/config.json';

export const I18n = createI18n({
  locale: 'ja',
  silentTranslationWarn: process.env.NODE_ENV !== 'development',
  silentFallbackWarn: process.env.NODE_ENV !== 'development',
  fallbackLocale: 'en',
  datetimeFormats: {
    en: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    ja: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    zh: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    tl: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    pt: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    es: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    th: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    vi: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    id: {
      year: {
        year: 'numeric'
      },
      month: {
        month: 'short'
      },
      day: {
        day: 'numeric'
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
  },
  messages: {
    en: {
      ...en,
      fields: enFields,
      modify: enModify,
      config: enConfig,
    },
    es: {
      ...es,
      fields: esFields,
      modify: esModify,
      config: esConfig,
    },
    id: {
      ...id,
      fields: idFields,
      modify: idModify,
      config: idConfig,
    },
    ja: {
      ...ja,
      fields: jaFields,
      modify: jaModify,
      config: jaConfig,
    },
    my: {
      ...my,
      fields: myFields,
      modify: myModify,
      config: myConfig,
    },
    pt: {
      ...pt,
      fields: ptFields,
      modify: ptModify,
      config: ptConfig,
    },
    th: {
      ...th,
      fields: thFields,
      modify: thModify,
      config: thConfig,
    },
    tl: {
      ...tl,
      fields: tlFields,
      modify: tlModify,
      config: tlConfig,
    },
    vi: {
      ...vi,
      fields: viFields,
      modify: viModify,
      config: viConfig,
    },
    zh: {
      ...zh,
      fields: zhFields,
      modify: zhModify,
      config: zhConfig,
    },
  }
});

export default I18n.global;
