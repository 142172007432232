import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Alert extends VuexModule {
  private _read = false;

  @Mutation
  reset() {
    this._read = false;
  }

  @Mutation
  read() {
    this._read = true;
  }

  get isRead() {
    return this._read;
  }
}
