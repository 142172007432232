import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class CanRecovery extends VuexModule {
  _can_recovery: boolean | null = null;

  @Mutation
  reset() {
    this._can_recovery = null;
  }

  @Mutation
  _set(value: boolean) {
    this._can_recovery = value;
  }

  get can_recovery(): boolean | null {
    return this._can_recovery;
  }
}
