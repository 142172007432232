import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import i18n from '@/plugins/i18n';

@Module({ namespaced: true })
export default class Terms extends VuexModule {
  _version: string | null = null;
  _body: string | null = null;
  _isShowMypageModal: boolean = false;

  @Mutation
  reset() {
    this._isShowMypageModal = false;
  }

  @Mutation
  _setIsShowMypageModal(value: boolean) {
    this._isShowMypageModal = value;
  }

  @Mutation
  _setVersion(value: string) {
    this._version = value;
  }

  @Mutation
  _setBody(value: string) {
    this._body = value;
  }

  /**
   * 利用規約の本文とバージョンを取得する
   *
   * @returns {Promise<void>}
   * @memberof Terms
   */
  @Action({ rawError: true })
  async getTerms(lang?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const queries = {
        locale: lang ? lang : i18n.locale
      };
      axios
        .get(API_DOMAIN + '/v1/terms', { params: queries })
        .then((response: any) => {
          // 返ってきたトークンをセット
          this.context.commit('_setVersion', response.data.ter_version);
          this.context.commit('_setBody', response.data.body);
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  get version(): string | null {
    return this._version;
  }

  get body(): string | null {
    return this._body;
  }

  get isShowMypageModal(): boolean {
    return this._isShowMypageModal;
  }
}
