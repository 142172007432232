import { createApp } from 'vue'
import { Vue } from 'vue-property-decorator';
import { RouteLocationNormalizedGeneric, RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import App from './App.vue';
import { router } from '@/router';
import { store, key } from '@/store';
import { handleError } from '@/plugins/ApiRequest';
import './plugins/vee-validate';
import { dfp } from './plugins/detecker-fpjs';
import { deviceMatches, DEVICE_SP } from '@/plugins/devices';
import sleep from '@/plugins/sleep';
import { ACCESS_ALLOW_ONLY_SP, GA_TRACKING_ID, DETECKER_PLUS_SCRIPT, STREAM_DETECKER_SCRIPT } from '@/plugins/getenv';
import config from '@/config';
import { IToken } from './types/token'
import i18n, { I18n } from './plugins/i18n';
import { setLocale as setValidateLocale } from '@vee-validate/i18n';
import { ILanguages } from './types/languages';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

// add bootstrap
import './assets/scss/bootstrap.scss';

// add basic
import './assets/scss/basic.scss';

// add axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// add smooth scroll
import VueScrollTo from 'vue-scrollto';

import VueGtag from 'vue-gtag';
import analytics, { VueGtagOptions } from '@/plugins/analytics';

// Global axios defaults
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.post['accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['accept'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';

// リクエスト時の共通処理
axios.interceptors.request.use(req => {
  store.commit('Processing/set', true);
  return req;
});

// レスポンス時の共通処理
axios.interceptors.response.use(
  res => {
    store.commit('Processing/set', false);
    return res;
  },
  error => {
    store.commit('Processing/set', false);
    throw error;
  }
);

// ブラウザバック検出フラグ undefined: ロード時, false: ブラウザバック未検出(通常), true: ブラウザバック検出
let popstate: boolean | undefined = undefined;
window.addEventListener('popstate', () => (popstate = true));

router.beforeEach(async (to: RouteLocationNormalizedGeneric, from: RouteLocationNormalizedLoadedGeneric) => {
  // スマートフォンのみアクセス可能（エラーページ等、許可されているページを除く）
  if (ACCESS_ALLOW_ONLY_SP && !to.meta?.allow_pc) {
    let matched: any = true;
    deviceMatches(DEVICE_SP, (m: boolean) => {
      matched = m;
    });

    if (!matched) {
      store.commit('errorMessage', i18n.t('error.device_not_match'));
      store.commit('errorTitle', i18n.t('error_page.title.device_error'));
      router
        .replace({
          name: 'error',
          params: {
            errorCode: config.ERROR_CODE.DEVICE_ERROR,
          }
        })
        .catch((error: any) => {
          console.warn(error);
        });
      return;
    }
  }

  // navigation guards
  if (popstate == undefined) {
    popstate = false;
  } else {
    // 非同期のブラウザバックのイベントを待つ
    await sleep(0);

    // ブラウザバック無効ページでブラウザバックを検出した場合、元居たページに戻す。
    if (popstate) {
      popstate = false;
      if (typeof from.name === 'string' && !from.meta?.enableBack) {
        return { name: from.name };
      }
    }
  }

  // use the language from the routing param or default language
  const language: ILanguages = to.params.lang as ILanguages || 'ja';
  i18n.locale = language;
  setValidateLocale(language);
  document.querySelector('html')!.setAttribute('lang', language);

  // 確認画面などの直接表示を禁止する処理
  if (to.matched.some((record: any) => record.meta.from && (from.name !== record.meta.from.name || !from.name))) {
    return { name: to.meta?.from?.redirect, params: { lang: to.params.lang } };
  }

  // codeのチェックは登録画面のトップページの場合のみ行う
  if (to.name === 'index' || to.name === 'faceauth.index') {
    // URL に付加されている code からトークンを取得
    const hash = new URLSearchParams(window.location.hash.substr(1));
    const code = hash.get('code');
    // 認証画面用トークンを取得する
    await store
      .dispatch('Auth/login', code)
      .catch((error: any) => {
        // APIでエラーの場合は認証エラーページへ(前提として、APIは認証エラーページに遷移するレスポンスを返すこと)
        handleError(error);
      });
    return;
  } else {
    // リロードで表示された場合のためにトークンを復帰する
    const token = store.getters['Auth/token'];
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  }
});

router.afterEach((to: RouteLocationNormalizedGeneric) => {
  // detecker plus
  if (DETECKER_PLUS_SCRIPT) {
    dfp(DETECKER_PLUS_SCRIPT);
  }

  if (STREAM_DETECKER_SCRIPT) {
    if (
      to.name === "register.complete" ||
      to.name === "registered.complete" ||
      to.name === "top" ||
      to.name === "register.user.create"
    ) {
      dfp(STREAM_DETECKER_SCRIPT);
    }
  }
});

// navigation guards でエラーが検出されたときに呼び出されるコールバック
router.onError((error: any) => {
  handleError(error);
});

// ピンチアウトのズーム無効化
document.addEventListener(
  'touchstart',
  event => {
    if (event.touches.length >= 2) {
      event.preventDefault();
    }
  },
  { passive: false }
);

// ダブルタップによるズーム無効化
let lastTouchEnd = 0;
document.addEventListener(
  'touchend',
  event => {
    const now = new Date().getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  },
  false
);

// セッション期限を監視し、切れたらエラーページに遷移する
setInterval(() => {
  // store から token の有効期限を取得
  const expired: number | null = store.getters['Auth/expired'];
  if (expired === null) {
    return;
  }
  let now = Math.round(new Date().getTime() / 1000);
  // 顔認証の場合はタイムアウト時に API コールするので token の有効期限が切れる前にエラー画面に遷移する必要がある
  // そのため有効期限から 1 分（= 60 秒）早くタイムアウトするようにする
  const payload: IToken = store.getters['Auth/payload'];
  const entryPoint: string | undefined = payload ? payload.entry_point_api_name : undefined;
  if (entryPoint === 'faceAuthorize') {
    now += 60;
  }
  if (expired - now < 0) {
    router
      .replace({
        name: 'error',
        params: {
          errorCode: config.ERROR_CODE.AUTHENTICATION_EXPIRED,
          message: i18n.t('error.token_expired'),
          title: i18n.t('error_page.title.unauthorization_error')
        }
      })
      .catch((error: any) => {
        console.warn(error);
      });
  }
}, 3000);

// 各class内に記載している以下の関数をナビゲーションガードに登録する.(@Optionsに記載するのと同等)
Vue.registerHooks([
  'beforeRouteEnter',
]);

const app = createApp(App);
app.config.errorHandler = (err) => {
  console.error(err);
}

if (GA_TRACKING_ID !== '') {
  app.use(VueGtag, VueGtagOptions);
  app.use(analytics);
}

app.use(router);
app.use(store, key);
app.use(I18n);
app.use(ToastPlugin);
app.use(VueAxios, axios);
app.use(VueScrollTo, {
  container: 'body',
  duration: 800,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

app.mount('#app');
