import { Vue } from 'vue-property-decorator';
import { RouteLocationNormalizedGeneric } from 'vue-router';
import { router } from '@/router';
import { store } from '@/store';
import { IError } from '@/types/error';
import i18n from '@/plugins/i18n';
import config from '@/config';
import { toast } from '@/plugins/Toast';

/**
 * エラーページのパスを返す
 *
 * @returns {string}
 */
export const getErrorPagePath = (): string => {
  return '/common/static/error';
}

/**
 * エラーページ名を返す
 *
 * @returns {string}
 */
export const getErrorPageName = (): string => {
  return 'error';
}

/**
 * エラーページのタイトルを返す
 *
 * @returns {string}
 */
const getErrorPageTitle = (error?: any): string => {
  let translationKey;

  if (isUnauthorizationError(error)) {
    translationKey = 'error_page.title.unauthorization_error';
  } else {
    translationKey = 'error_page.title.system_error';
  }

  // [lang].jsonからエラーメッセージを取得する
  return i18n.t(translationKey);
}

/**
 * APIレスポンスから、エラーメッセージを文字として取得
 *
 * @param {*} error
 * @returns {string}
 */
export const getErrorMessage = (error: any): string => {
  const errorMessageKey = getErrorMessageKey(error);

  if (!errorMessageKey) {
    return '';
  }

  const translationKey = 'error.' + errorMessageKey;

  // ※i18n.teはfallbackLocaleに対応していないためfallbackされず''が返る
  // APIのレスポンスがキーになる都合で、存在しないキーの可能性がありキーが画面表示されるため直せない
  if (!i18n.te(translationKey)) {
    console.warn(translationKey);
    return '';
  }

  // [lang].jsonからエラーメッセージを取得する
  return i18n.tc(translationKey);
}

/**
 * エラーコードを取得する.
 *
 * @param {*} error
 * @returns {string}
 */
const getUnauthorizationErrorCode = (error: any): string => {
  const errorMessageKey = getErrorMessageKey(error);
  switch(errorMessageKey) {
    // 画面遷移不正
    case "screen_transition_invalid":
      return config.ERROR_CODE.SCREEN_TRANSITION_INVALID;
    // アカウントロック
    case "account_locked":
      return config.ERROR_CODE.ACCOUNT_LOCK;
    // コード送信回数超過
    case "code_send_limit":
      return config.ERROR_CODE.CODE_SEND_LIMIT;
    // ATM住所認証不正
    case "atm_account_locked":
      return config.ERROR_CODE.ATM_ACCOUNT_LOCKED;
    // トークン不正
    case "token_invalid":
      return config.ERROR_CODE.TOKEN_ERROR;
    // 認証時間超過
    case "token_expired":
      return config.ERROR_CODE.AUTHENTICATION_EXPIRED;
    // コード不正
    case "code_invalid":
      return config.ERROR_CODE.CODE_INVALID;
    // 顔更新不正
    case "face_update_invalid":
      return config.ERROR_CODE.FACE_UPDATE_INVALID;
    // メール認証無効なリンク
    case "email_authorization_error":
      return config.ERROR_CODE.EMAIL_AUTHORIZATION_ERROR;
    // メール認証リンクの有効期限切れ
    case "email_authorization_limit":
      return config.ERROR_CODE.EMAIL_AUTHORIZATION_LIMIT;
    // (顔認証用)メール/SMS認証不正
    case "face_auth_otp_invalid":
      return config.ERROR_CODE.FACE_AUTH_OTP_INVALID;
    // (顔認証用)顔認証不正
    case "face_match_invalid":
      return config.ERROR_CODE.FACE_MATCH_INVALID;
    default:
      return config.ERROR_CODE.OTHER;
  }
}

/**
 * メンテナンスページのパスを返す
 *
 * @returns {string}
 */
const getMaintenancePageName = (): string => {
  return 'maintenance';
}

export const handleError = (error: any, vue?: Vue, route?: RouteLocationNormalizedGeneric): void => {
  console.warn(error);

  // メンテナンス中
  if (isMaintenance(error)) {
    moveMaintenancePage();
    return;
  }

  // システムエラー
  if (isSystemError(error)) {
    moveSystemErrorPage();
    return;
  }

  const errorMessage = getErrorMessage(error);

  // code関連とトークン関連のエラーは認証エラーにする
  if (isUnauthorizationError(error)) {
    const errorCode = getUnauthorizationErrorCode(error);
    const title = getErrorPageTitle(error);
    const errorPageName = getErrorPageName();
    store.commit('errorMessage', errorMessage);
    store.commit('errorTitle', title);
    router.replace({ name: errorPageName, params: { errorCode: errorCode } });
    return;
  }

  // 業務エラーは画面上部にトースト形式でエラーメッセージを表示する
  if (vue && route) {
    showBusinessError(errorMessage, vue, route);
  }
}

/**
 * エラーメッセージを返す
 *
 * @param error APIレスポンスまたはnew Error('xxx')
 * @returns {string}
 */
const getErrorMessageKey = (error?: any): string => {
  const response = error && error.response;

  // new Error('xxx')の場合
  if (!response) {
    return (error && error.message) || '';
  }

  // AxiosErrorの場合
  const data: IError = response && response.data;
  return (data && data.errors && data.errors.length) ? data.errors[0] : '';
}

/**
 * メンテナンス中の場合はtrueを返す
 *
 * @param {*} error - error
 * @returns {boolean}
 */
const isMaintenance = (error: any): boolean => {
  return error && error.response && error.response.status === 503;
}

/**
 * システムエラーの場合はtrueを返す
 *
 * @param {*} error - error
 * @returns {boolean}
 */
const isSystemError = (error: any): boolean => {
  return error && error.response && error.response.status === 500;
}

/**
 * 認証エラーの場合はtrueを返す
 *
 * @param {*} error - error
 * @returns {boolean}
 */
const isUnauthorizationError = (error: any): boolean => {
  const errorInfo = getErrorMessageKey(error);
  const status = error && error.response && error.response.status;
  return status === 401 || errorInfo.indexOf('code_') === 0 || errorInfo.indexOf('token_') === 0;
}

// メンテナンス中
const moveMaintenancePage = (): void => {
  const maintenancePageName = getMaintenancePageName();
  router.replace({ name: maintenancePageName });
}

// システムエラー
const moveSystemErrorPage = (): void => {
  const name = getErrorPageName();
  store.commit('errorMessage', i18n.t('error.system_error'));
  store.commit('errorTitle', i18n.t('error_page.title.system_error'));
  router.replace({ name: name, params: { errorCode: config.ERROR_CODE.SYSTEM_ERROR } });
}

// 業務エラー
const showBusinessError = (errorMessage: string, vue: Vue, route: RouteLocationNormalizedGeneric): void => {
  const meta = route.meta;

  if (meta?.from?.redirect) {
    // リダイレクト先にエラーメッセージを表示する
    store.commit('errorMessage', errorMessage);
    router.push({
      name: meta.from.redirect,
      params: { lang: route.params.lang }
    });
  } else if (errorMessage) {
    toast(errorMessage, vue);
  }
}
