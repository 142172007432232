import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'faceauth/index',
    name: 'faceauth.index',
    meta: {
      hidden_header_logo: true,
      showCompanyLogo: true
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/FaceAuthIndex.vue')
    }
  },
  {
    path: 'faceauth/mail',
    name: 'faceauth.mail',
    meta: {
      close_name: 'faceauth.index',
      next: 'faceauth.mail.store',
      action: 'face_authentication'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterAuthMail.vue')
    }
  },
  {
    path: 'faceauth/mail/store',
    name: 'faceauth.mail.store',
    meta: {
      from: {
        name: 'faceauth.mail',
        redirect: 'faceauth.mail'
      },
      next: 'faceauth.phone',
      action: 'face_authentication'
    },
    components: {
      upper: () => import('@/views/upper/RegisterMailCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'faceauth/phone',
    name: 'faceauth.phone',
    meta: {
      close_name: 'faceauth.index',
      next: 'faceauth.phone.store',
      action: 'face_authentication'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterAuthPhone.vue')
    }
  },
  {
    path: 'faceauth/phone/store',
    name: 'faceauth.phone.store',
    meta: {
      from: {
        name: 'faceauth.phone',
        redirect: 'faceauth.phone'
      },
      next: 'faceauth.match.face',
      action: 'face_authentication'
    },
    components: {
      upper: () => import('@/views/upper/RegisterPhoneCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'faceauth/match',
    name: 'faceauth.match',
    components: {
      upper: () => import('@/views/RegisterPhotoMedia.vue'),
      lower: () => import('@/components/Empty.vue')
    },
    children: [
      {
        path: 'face',
        name: 'faceauth.match.face',
        meta: {
          video: {
            facingMode: 'user',
          },
          next: 'faceauth.match.redirect'
        },
        component: () => import('@/components/photo/FaceRecognition.vue')
      }
    ]
  },
  {
    path: 'faceauth/match/redirect',
    name: 'faceauth.match.redirect',
    meta: {
      hidden_header_logo: true
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/FaceAuthRedirect.vue')
    }
  }
] as RouteRecordRaw[];
