import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import { isMyPage } from '@/plugins/token-service'
import { store } from '@/store';

@Module({ namespaced: true })
export default class Globals extends VuexModule {
  _mockCompanyIds: string[] = [];
  _apiVersion: string = '';

  @Mutation
  _setMockCompanyIds(value: []) {
    this._mockCompanyIds = value;
  }

  @Mutation
  _setApiVersion(value: string) {
    this._apiVersion = value;
  }

  /**
   * globals レスポンス取得
   *
   * @returns {Promise<boolean>}
   * @memberof Globals
   */
  @Action({ rawError: true })
  async getGlobals(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .get(API_DOMAIN + '/v1/globals')
        .then((response: any) => {
          this.context.commit('_setMockCompanyIds', response.data.mock_company_ids);
          this.context.commit('_setApiVersion', response.data.api_version);
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  get isUseMock(): boolean {
    if (isMyPage()) {
      return this._mockCompanyIds.includes('MYPAGE');
    } else {
      const companyId = store.getters['company/companyId'];
      return this._mockCompanyIds.includes(companyId);
    }
  }

  get apiVersion(): string {
    return this._apiVersion;
  }
}
