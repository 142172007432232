import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex';
import alert from '@/store/modules/alert';
import AttributeInputItems from '@/store/modules/attribute_input_items';
import AttributeInitialValue from '@/store/modules/attributeInitialValue';
import Attributes from '@/store/modules/attributes';
import Auth from '@/store/modules/auth';
import CanRecovery from '@/store/modules/can_recovery';
import companies from '@/store/modules/companies';
import company from '@/store/modules/company';
import ConfirmCode from '@/store/modules/confirm_code';
import Customizes from '@/store/modules/customizes';
import DocumentType from '@/store/modules/document_type';
import Express from '@/store/modules/express';
import Globals from '@/store/modules/globals';
import HighResolution from '@/store/modules/high_resolution';
import modifications from '@/store/modules/modifications';
import Processing from '@/store/modules/processing';
import Session from '@/store/modules/session';
import StreamDetecker from '@/store/modules/stream_detecker';
import Terms from '@/store/modules/terms';
import User from '@/store/modules/user';
import createPersistedState from 'vuex-persistedstate';
import { USE_STRICT_MODE } from '@/plugins/getenv';

// ストアのステートに対して型を定義します
interface State {
  canPaste: boolean,
  /** エラー画面のタイトル. */
  errorTitle: string | null,
  /** エラー画面またはトーストのエラーメッセージ. */
  errorMessage: string | null,
  /** 会員属性登録/更新で発生したエラーメッセージキー. */
  errors: string[] | null,
  /** 企業に登録済みか. */
  comRegistered: boolean,
  /** 写真撮影のみか. */
  photoOnly: boolean,
  /** マイページログイン時に利用規約を表示したか. */
  isTermsOpened: boolean,
  /** live撮影枚数. */
  numberOfShots: number,
  /** 退会受付送信の残り回数. */
  remain: number | null,
  /** メールアドレス. */
  email: string | null,
  /** 電話番号. */
  phoNumber: string | null,
  /** 認証コード. */
  code: string | null,
  /** 会員属性. */
  formValues: any,
  /** 退会理由. */
  comment: string | null,
}

// インジェクションキーを定義します
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore({
  state: {
    canPaste: false,
    errorTitle: null,
    errorMessage: null,
    errors: null,
    comRegistered: false,
    photoOnly: false,
    isTermsOpened: false,
    numberOfShots: 5,
    remain: 3,
    email: null,
    phoNumber: null,
    code: null,
    formValues: null,
    comment: null,
  },
  strict: USE_STRICT_MODE,
  mutations: {
    enablePaste(state) {
      state.canPaste = true;
    },
    disablePaste(state) {
      state.canPaste = false;
    },
    errorTitle(state, value: string | null) {
      state.errorTitle = value;
    },
    errorMessage(state, value: string | null) {
      state.errorMessage = value;
    },
    errors(state, value: string[] | null) {
      state.errors = value;
    },
    comRegistered(state, value: boolean) {
      state.comRegistered = value;
    },
    photoOnly(state, value: boolean) {
      state.photoOnly = value;
    },
    isTermsOpened(state, value: boolean) {
      state.isTermsOpened = value;
    },
    numberOfShots(state, value: number) {
      state.numberOfShots = value;
    },
    remain(state, value: number | null) {
      state.remain = value;
    },
    email(state, value: string | null) {
      state.email = value;
    },
    phoNumber(state, value: string | null) {
      state.phoNumber = value;
    },
    code(state, value: string | null) {
      state.code = value;
    },
    formValues(state, value: any) {
      state.formValues = value;
    },
    comment(state, value: string | null) {
      state.comment = value;
    },
  },
  actions: {
    resetAll({ commit }) {
      // storageを全てリセットする
      commit('AttributeInputItems/reset');
      commit('AttributeInitialValue/reset');
      commit('Attributes/reset');
      commit('Auth/reset');
      commit('companies/reset');
      commit('company/reset');
      commit('CanRecovery/reset');
      commit('ConfirmCode/reset');
      commit('Customizes/reset');
      commit('DocumentType/reset');
      commit('Express/reset');
      commit('HighResolution/reset');
      commit('modifications/reset');
      commit('Processing/reset');
      commit('Session/reset');
      commit('StreamDetecker/reset');
      commit('Terms/reset');
      commit('User/reset');
      commit('errorTitle', null);
      commit('errorMessage', null);
      commit('errors', null);
      commit('comRegistered', false);
      commit('photoOnly', false);
      commit('isTermsOpened', false);
      commit('numberOfShots', 5);
      commit('remain', 3);
      commit('email', null);
      commit('phoNumber', null);
      commit('code', null);
      commit('formValues', null);
      commit('comment', null);
    }
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer: (state: any) => ({
        // storageに保存するモジュール
        // 個人情報はstorageに保存しない
        alert: state.alert,
        AttributeInputItems: state.AttributeInputItems,
        Attributes: state.Attributes,
        Auth: state.Auth,
        CanRecovery: state.CanRecovery,
        companies: state.companies,
        company: state.company,
        ConfirmCode: state.ConfirmCode,
        Customizes: state.Customizes,
        DocumentType: state.DocumentType,
        // 個人情報: Express
        Globals: state.Globals,
        HighResolution: state.HighResolution,
        modifications: state.modifications,
        // Processing
        Session: state.Session,
        StreamDetecker: state.StreamDetecker,
        Terms: state.Terms,
        // 個人情報: User
        errorTitle: state.errorTitle,
        errorMessage: state.errorMessage,
        errors: state.errors,
        comRegistered: state.comRegistered,
        photoOnly: state.photoOnly,
        isTermsOpened: state.isTermsOpened,
        numberOfShots: state.numberOfShots,
        remain: state.remain,
        // 個人情報: email
        // 個人情報: phoNumber
        // 個人情報: code
        // 個人情報: formValues
        // 個人情報: comment
      })
    })
  ],
  // ストアに含むモジュール
  modules: {
    alert,
    AttributeInputItems,
    AttributeInitialValue,
    Attributes,
    Auth,
    CanRecovery,
    companies,
    company,
    ConfirmCode,
    Customizes,
    DocumentType,
    Express,
    Globals,
    HighResolution,
    modifications,
    Processing,
    Session,
    StreamDetecker,
    Terms,
    User
  }
});
