import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class StreamDetecker extends VuexModule {
  _params: string | null = null;
  _screen: string | null = null;

  @Mutation
  reset() {
    this._params = null;
    this._screen = null;
  }

  @Mutation
  _setParams(value: string) {
    this._params = value;
  }

  @Mutation
  _setScreen(value: string) {
    this._screen = value;
  }

  /**
   * 情報取得
   *
   * @readonly
   * @type {(string | null)}
   * @memberof User
   */
  get params(): string | null {
    return this._params;
  }

  get screen(): string | null {
    return this._screen;
  }

  /**
   * パラメータを設定する
   *
   * @param params string : json形式のパラメータ
   */
   @Action({})
   setParams(params: string) {
    this.context.commit('_setParams', params);
  }

  /**
   * 画面を設定する
   *
   * @param screen string : 画面
   */
   @Action({})
   setScreen(screen: string) {
    this.context.commit('_setScreen', screen);
  }
}
