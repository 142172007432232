import { RouteRecordRaw } from 'vue-router';

/**
 * 退会関連のルーティング設定
 */
export default [
  {
    path: 'unsubscribe/complete',
    name: 'unsubscribe.complete',
    meta: {
      close_name: 'lp',
      from: {
        name: 'unsubscribe.confirm',
        redirect: 'unsubscribe'
      }
    },
    components: {
      upper: () => import('@/views/upper/UnsubscribeComplete.vue'),
      lower: () => import('@/components/EmptyLower.vue')
    }
  },
  {
    path: 'unsubscribe',
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/Unsubscribe.vue')
    },
    children: [
      {
        path: 'input',
        name: 'unsubscribe',
        meta: {
          close_name: 'top'
        },
        components: {
          lower: () => import('@/components/UnsubscribeForm.vue')
        }
      },
      {
        path: 'confirm',
        name: 'unsubscribe.confirm',
        meta: {
          close_name: 'top',
          from: {
            name: 'unsubscribe',
            redirect: 'unsubscribe'
          }
        },
        components: {
          lower: () => import('@/components/UnsubscribeConfirm.vue')
        }
      }
    ]
  },
  {
    path: 'unsubscribe/code',
    name: 'unsubscribe.code',
    meta: {
      next: 'unsubscribe.code.complete',
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/UnsubscribeCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'unsubscribe/code/complete',
    name: 'unsubscribe.code.complete',
    meta: {
      from: {
        name: 'unsubscribe.code',
        redirect: 'unsubscribe'
      },
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/UnsubscribeCodeComplete.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  }
] as RouteRecordRaw[];
