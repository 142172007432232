import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';

export interface CompanyName {
  en?: string;
  ja?: string;
  [key: string]: any;
}
export interface Company {
  cli_id?: number;
  cli_name?: CompanyName;
  com_id?: string;
  opt_in_date?: string;
  opt_out_date?: string;
  terms_url?: string;
}

@Module({ namespaced: true })
export default class CompaniesModule extends VuexModule {
  private _companies: Company[] = [];

  @Mutation
  reset() {
    this._companies = [];
  }

  @Mutation
  SET_COMPANIES(value: Company[]) {
    this._companies = value;
  }

  /**
   * APIから企業情報を取得
   *
   * @returns {Promise<void>}
   * @memberof CompaniesModule
   */
  @Action({ rawError: true })
  async load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(API_DOMAIN + '/v1/companies')
        .then((response: any) => {
          this.context.commit("SET_COMPANIES", response.data.companies);
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * 企業情報を取得
   *
   * @readonly
   * @type {Company[]}
   * @memberof CompaniesModule
   */
  get companies(): Company[] {
    return this._companies;
  }
}
