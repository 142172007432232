import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { MailStatus } from '@/types/mailStatus'

@Module({ namespaced: true })
export default class Attributes extends VuexModule {
  _mail_status: MailStatus | null = null

  @Mutation
  reset() {
    this._mail_status = null
  }

  @Mutation
  _setMailStatus(value: MailStatus) {
    this._mail_status = value;
  }

  /**
   * メールアドレス登録状況取得
   *
   * @readonly
   * @type { boolean }
   * @memberof Attributes
   */
  get mail_status(): MailStatus | null {
    return this._mail_status;
  }
}
