export const dfp: Function = function (url: string, name?: any) {
  // @ts-ignore
  (name = name || 'dfpr'), (window.detecker_fp = name);
  window[name] =
    window[name] ||
    function (key: any, value: any) {
      // @ts-ignore
      (window[name].l = window[name].l || []).push([key, value]);
    };
  const script = document.createElement('script'),
    firstScript = document.getElementsByTagName('script')[0];
  (script.src = url);
  // @ts-ignore
  firstScript.parentNode.insertBefore(script, firstScript);
};
