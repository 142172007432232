import {
  Action,
  Module,
  VuexModule,
  Mutation
} from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import i18n from '@/plugins/i18n';
import { ICompany } from '@/types/company';
import { ILocale } from '@/types/locale';
import { ILanguages } from '@/types/languages';
import { isMyPage, isSevenBankResidence } from '@/plugins/token-service';

@Module({ namespaced: true })
export default class CompanyModule extends VuexModule {
  private _clientId: number = 0;
  private _companyId: string = '';
  private _companyNames: ILocale = {};
  private _companyLogos: ILocale = {};
  private _displayLanguages: string = '';

  @Mutation
  reset() {
    this._clientId = 0;
    this._companyId = '';
    this._companyNames = {};
    this._companyLogos = {};
    this._displayLanguages = '';
  }

  @Mutation
  _setClientId(value: number) {
    this._clientId = value;
  }

  @Mutation
  _setCompanyId(value: string) {
    this._companyId = value;
  }

  @Mutation
  _setCompanyNames(value: ILocale) {
    this._companyNames = value;
  }

  @Mutation
  _setCompanyLogos(value: ILocale) {
    this._companyLogos = value;
  }

  @Mutation
  _setComapnyLogosEach(payload: any) {
    this._companyLogos[payload.target] = payload.value;
  }

  @Mutation
  _setDisplayLanguages(value: string) {
    this._displayLanguages = value;
  }

  /**
   * APIから企業情報を取得
   *
   * @params {number} cli_id ATMPoC用に追加したパラメータのため現状は渡されない
   * @returns {Promise<boolean>}
   * @memberof Company
   */
  @Action({ rawError: true })
  async load(cli_id?: number): Promise<boolean> {
    if (isMyPage() && !cli_id) {
      return Promise.resolve(true);
    }
    return new Promise((resolve, reject) => {
      const query = cli_id ? '?cli_id=' + cli_id : '';
      axios
        .get(API_DOMAIN + '/v1/company' + query)
        .then((response: any) => {
          const company: ICompany = response.data;

          // 日英の企業名設定
          this.context.commit('_setCompanyNames', company.cli_name);

          let Logos: ILocale | null = null;
          // ロゴ情報取得
          this.context
            .dispatch('createLogoObject', company.com_id)
            .then((data: ILocale) => {
              Logos = data;
            });

          // ロゴ取得
          const funcs: Array<any> = [];
          if (Logos) {
            const _companyLogos = this.context.getters["_companyLogos"];
            Object.keys(Logos).forEach((langKey: string) => {
              funcs.push(
                this.context
                  .dispatch('checkLogo', _companyLogos[langKey])
                  .catch((error: any) => {
                    console.warn(error);
                    this.context.commit('_setComapnyLogosEach', {
                      target: langKey,
                      value: undefined
                    });
                  })
              );
            });
          }

          this.context.commit('_setClientId', company.cli_id);
          this.context.commit('_setCompanyId', company.com_id);
          this.context.commit('_setDisplayLanguages', company.display_languages);

          Promise.all(funcs).finally(() => resolve(true));
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * ロゴのURLを取得
   *
   * @readonly
   * @type {Promise<boolean>}
   * @memberof Company
   */
  @Action({})
  async checkLogo(path: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.onerror = (error: any) => {
        reject(error);
      };
      img.src = path;
    });
  }

  /**
   * ロゴURLを取得
   * @readonly
   * @param com_id
   * @memberof Company
   */
  @Action({})
  async createLogoObject(com_id: string | undefined): Promise<ILocale> {
    // 日英のロゴのパスを設定
    const createPath = (locale: string): string => {
      return '/logo/' + com_id + '_' + locale + '.png';
    };

    let Logos: ILocale = {
      ja: createPath('ja'),
      en: createPath('en')
    };

    if (isSevenBankResidence()) {
      const AdditionalLogos: ILocale = {
        zh: createPath('zh'),
        tl: createPath('tl'),
        pt: createPath('pt'),
        es: createPath('es'),
        th: createPath('th'),
        vi: createPath('vi'),
        id: createPath('id')
      };

      Logos = Object.assign(Logos, AdditionalLogos);
    }

    await this.context.commit('_setCompanyLogos', Logos);

    return Logos;
  }

  /**
   * ロゴのURLを取得
   *
   * @readonly
   * @type {string | undefined}
   * @memberof Company
   */
  get logo(): string | undefined {
    if (this._companyLogos[i18n.locale]) {
      return this._companyLogos[i18n.locale];
    } else if (this._companyLogos['en']) {
      return this._companyLogos['en'];
    } else {
      return this._companyLogos['ja'];
    }
  }

  /**
   * 企業名を取得
   *
   * @readonly
   * @type {string | undefined}
   * @memberof Company
   */
  get name(): string | undefined {
    if (this._companyNames[i18n.locale]) {
      return this._companyNames[i18n.locale];
    } else if (this._companyNames['en']) {
      return this._companyNames['en'];
    } else {
      return this._companyNames['ja'];
    }
  }

  /**
   * クライアント企業情報IDを取得
   */
  get clientId(): number {
    return this._clientId;
  }

  /**
   * 企業IDを取得
   */
  get companyId(): string {
    return this._companyId;
  }

  /**
   * 表示言語を取得
   */
  get displayLanguages(): ILanguages[] {
    const languages: ILanguages[] = [];

    if (this._displayLanguages) {
      this._displayLanguages.split(',').map((_name: string) => {
        languages.push(_name as ILanguages);
      });
    }

    return languages;
  }
}
