import { RouteRecordRaw } from 'vue-router';

/**
 * 撮影関連のルーティング設定
 */

// liveDetection撮影の共通メタ情報
const meta_live = {
  hidden_header_logo: true,
  video: {
    facingMode: 'user'
  }
};

// セルフィー撮影の共通メタ情報
const meta_selfie = {
  hidden_header_logo: true,
  video: {
    facingMode: 'user'
  }
};

// セルフィー+裏面撮影の共通メタ情報
const meta_selfie_back = {
  hidden_header_logo: true,
  video: {
    facingMode: 'user'
  }
}

// 本人確認書類表面撮影の共通メタ情報
const meta_front = {
  hidden_header_logo: true,
  video: {
    facingMode: 'environment'
  }
};

// 本人確認書類裏面撮影の共通メタ情報
const meta_back = {
  hidden_header_logo: true,
  video: {
    facingMode: 'environment'
  }
};

// 本人確認書類傾き撮影の共通メタ情報
const meta_slope = {
  hidden_header_logo: true,
  video: {
    facingMode: 'environment'
  }
};

export default [
  {
    path: 'register/photo',
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/RegisterPhotoMedia.vue')
    },
    children: [
      {
        path: 'live',
        name: 'register.photo.live',
        meta: {
          ...meta_live,
          next: 'register.photo.selfie',
          nextUser: 'register.user',
          nextComplete: 'register.complete',
          showCompanyLogo: true
        },
        component: () => import('@/components/photo/LiveDetection.vue')
      },
      {
        path: 'selfie',
        name: 'register.photo.selfie',
        meta: {
          ...meta_selfie,
          retake: 'register.photo.live',
          next: {
            GREEN: 'register.photo.front',
            SILVER: 'register.photo.front',
            GOLD: 'register.photo.selfieBack'
          },
          next_mynumber: 'register.photo.front'
        },
        component: () => import('@/components/photo/Selfie.vue')
      },
      {
        path: 'selfieBack',
        name: 'register.photo.selfieBack',
        meta: {
          ...meta_selfie_back,
          retake: 'register.photo.live',
          next: 'register.photo.front'
        },
        component: () => import('@/components/photo/SelfieBack.vue')
      },
      {
        path: 'front',
        name: 'register.photo.front',
        meta: {
          ...meta_front,
          retake: 'register.photo.live',
          next: {
            GREEN: 'register.photo.back',
            SILVER: 'register.photo.back',
            GOLD: 'register.photo.slope'
          },
          next_mynumber: {
            GREEN: 'register.complete',
            SILVER: 'register.user',
            GOLD: 'register.photo.slope'
          }
        },
        component: () => import('@/components/photo/Front.vue')
      },
      {
        path: 'slope',
        name: 'register.photo.slope',
        meta: {
          ...meta_slope,
          retake: 'register.photo.live',
          next: 'register.photo.back',
          next_mynumber: 'register.user'
        },
        component: () => import('@/components/photo/Slope.vue')
      },
      {
        path: 'back',
        name: 'register.photo.back',
        meta: {
          ...meta_back,
          retake: 'register.photo.live',
          next: {
            GREEN: 'register.complete',
            SILVER: 'register.user',
            GOLD: 'register.user'
          }
        },
        component: () => import('@/components/photo/Back.vue')
      }
    ]
  },
  {
    path: 'registered/photo',
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/RegisterPhotoMedia.vue')
    },
    children: [
      {
        path: 'live',
        name: 'registered.photo.live',
        meta: {
          closeModificationsPhoto: 'top',
          ...meta_live,
          retake: 'registered.photo.live',
          next: 'registered.photo.selfie',
          nextUser: 'registered.user',
          nextComplete: 'registered.complete',
          showCompanyLogo: true
        },
        component: () => import('@/components/photo/LiveDetection.vue')
      },
      {
        path: 'selfie',
        name: 'registered.photo.selfie',
        meta: {
          ...meta_selfie,
          retake: 'registered.photo.live',
          next: {
            GREEN: 'registered.photo.front',
            SILVER: 'registered.photo.front',
            GOLD: 'registered.photo.selfieBack'
          },
          next_mynumber: 'registered.photo.front'
        },
        component: () => import('@/components/photo/Selfie.vue')
      },
      {
        path: 'selfieBack',
        name: 'registered.photo.selfieBack',
        meta: {
          ...meta_selfie_back,
          retake: 'registered.photo.live',
          next: 'registered.photo.front'
        },
        component: () => import('@/components/photo/SelfieBack.vue')
      },
      {
        path: 'front',
        name: 'registered.photo.front',
        meta: {
          ...meta_front,
          retake: 'registered.photo.live',
          next: {
            GREEN: 'registered.photo.back',
            SILVER: 'registered.photo.back',
            GOLD: 'registered.photo.slope'
          },
          next_mynumber: {
            GREEN: 'registered.user',
            SILVER: 'registered.user',
            GOLD: 'registered.photo.slope'
          }
        },
        component: () => import('@/components/photo/Front.vue')
      },
      {
        path: 'slope',
        name: 'registered.photo.slope',
        meta: {
          ...meta_slope,
          retake: 'registered.photo.live',
          next: 'registered.photo.back',
          next_mynumber: 'registered.user'
        },
        component: () => import('@/components/photo/Slope.vue')
      },
      {
        path: 'back',
        name: 'registered.photo.back',
        meta: {
          ...meta_back,
          retake: 'registered.photo.live',
          next: 'registered.user'
        },
        component: () => import('@/components/photo/Back.vue')
      }
    ]
  }
] as RouteRecordRaw[];
