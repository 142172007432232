import { RouteRecordRaw } from 'vue-router';

/**
 * メールアドレス、電話番号認証関連のルーティング設定
 */
export default [
  {
    path: 'register/static/proost',
    name: 'login',
    meta: {
      close_name: 'index',
      showCompanyLogo: true,
      next: 'register.store',
      action: 'authentication_register'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterAuthPhone.vue')
    }
  },
  {
    path: 'register/static/proost/store',
    name: 'register.store',
    meta: {
      showCompanyLogo: true,
      from: {
        name: 'login',
        redirect: 'login'
      },
      next: 'register.auth',
      action: 'authentication_register'
    },
    components: {
      upper: () => import('@/views/upper/RegisterPhoneCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'common/auth',
    name: 'register.auth',
    meta: {
      close_name: 'index',
      showCompanyLogo: true,
      next: 'register.auth.store',
      action: 'authentication_register'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterAuthMail.vue')
    }
  },
  {
    path: 'common/auth/store',
    name: 'register.auth.store',
    meta: {
      showCompanyLogo: true,
      from: {
        name: 'register.auth',
        redirect: 'register.auth'
      },
      next: 'register.photo.live',
      action: 'authentication_register'
    },
    components: {
      upper: () => import('@/views/upper/RegisterMailCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'common/auth/photo',
    name: 'register.auth.photo',
    components: {
      upper: () => import('@/views/RegisterPhotoMedia.vue'),
      lower: () => import('@/components/Empty.vue')
    },
    children: [
      {
        path: 'face',
        name: 'register.auth.photo.face',
        meta: {
          video: {
            facingMode: 'user',
          },
          next: 'registered.userinfo',
          action: 'authentication_mypage',
        },
        component: () => import('@/components/photo/FaceRecognition.vue')
      }
    ]
  },
  {
    path: 'mypage/change/mail',
    name: 'auth.mail',
    meta: {
      close_name: 'top',
      next: 'auth.mail.store',
      action: 'change_email'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisteredAuthMail.vue')
    }
  },
  {
    path: 'mypage/change/mail/store',
    name: 'auth.mail.store',
    meta: {
      from: {
        name: 'auth.mail',
        redirect: 'auth.mail'
      },
      next: 'top',
      action: 'change_email'
    },
    components: {
      upper: () => import('@/views/upper/RegisterMailCodeLoading.vue'),
      lower: () => import('@/components/EmptyLower.vue')
    }
  },
  {
    path: 'mypage/change/phone',
    name: 'auth.phone',
    meta: {
      close_name: 'top',
      next: 'auth.phone.store',
      action: 'change_pho_number'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisteredAuthPhone.vue')
    }
  },
  {
    path: 'mypage/change/phone/store',
    name: 'auth.phone.store',
    meta: {
      from: {
        name: 'auth.phone',
        redirect: 'auth.phone'
      },
      next: 'top',
      action: 'change_pho_number'
    },
    components: {
      upper: () => import('@/views/upper/RegisterPhoneCodeLoading.vue'),
      lower: () => import('@/components/EmptyLower.vue')
    }
  },
  {
    path: 'mypage/auth/mail',
    name: 'mypage.auth.mail',
    meta: {
      next: 'mypage.auth.mail.store',
      action: 'authentication_mypage',
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterAuthMail.vue')
    }
  },
  {
    path: 'mypage/auth/mail/store',
    name: 'mypage.auth.mail.store',
    meta: {
      from: {
        name: 'mypage.auth.mail',
        redirect: 'mypage.auth.mail'
      },
      next: 'top',
      action: 'authentication_mypage'
    },
    components: {
      upper: () => import('@/views/upper/RegisterMailCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'mypage/auth/phone',
    name: 'mypage.login',
    meta: {
      close_name: 'lp',
      next: 'mypage.auth.phone.store',
      action: 'authentication_mypage',
      public_path: true
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterAuthPhone.vue')
    }
  },
  {
    path: 'mypage/auth/phone/store',
    name: 'mypage.auth.phone.store',
    meta: {
      from: {
        name: 'mypage.login',
        redirect: 'mypage.login'
      },
      next: 'mypage.auth.photo.face',
      action: 'authentication_mypage',
    },
    components: {
      upper: () => import('@/views/upper/RegisterPhoneCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'mypage/auth/photo',
    name: 'mypage.auth.photo',
    components: {
      upper: () => import('@/views/RegisterPhotoMedia.vue'),
      lower: () => import('@/components/Empty.vue')
    },
    children: [
      {
        path: 'face',
        name: 'mypage.auth.photo.face',
        meta: {
          video: {
            facingMode: 'user',
          },
          next: 'top',
          action: 'authentication_mypage',
        },
        component: () => import('@/components/photo/FaceRecognition.vue')
      }
    ]
  },
  {
    path: 'mypage/auth/atmAddress',
    name: 'mypage.auth.atmAddress',
    meta: {
      next: 'mypage.auth.mail',
      action: 'authentication_mypage'
    },
    components: {
      upper: () => import('@/views/lower/CheckAtmAddress.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'mypage/recovery/speed',
    name: 'recovery.speed',
    meta: {
      close_name: 'lp',
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterSpeedUser.vue')
    }
  },
  {
    path: 'mypage/recovery/phone/old',
    name: 'recovery.phone.old',
    meta: {
      next: 'recovery.phone.photo.face',
      action: 'recovery_pho_number'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisteredAuthPhone.vue')
    }
  },
  {
    path: 'mypage/recovery/phone/new',
    name: 'recovery.phone.new',
    meta: {
      next: 'recovery.phone.new.store',
      action: 'recovery_register_pho_number',
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisteredAuthPhone.vue')
    }
  },
  {
    path: 'mypage/recovery/phone/new/store',
    name: 'recovery.phone.new.store',
    meta: {
      from: {
        name: 'recovery.phone.new',
        redirect: 'recovery.phone.new',
      },
      next: 'top',
      action: 'recovery_register_pho_number'
    },
    components: {
      upper: () => import('@/views/upper/RegisterPhoneCodeLoading.vue'),
      lower: () => import('@/components/EmptyLower.vue')
    }
  },
  {
    path: 'mypage/recovery/phone/photo',
    components: {
      upper: () => import('@/views/RegisterPhotoMedia.vue'),
      lower: () => import('@/components/Empty.vue')
    },
    children: [
      {
        path: 'face',
        name: 'recovery.phone.photo.face',
        meta: {
          video: {
            facingMode: 'user',
          },
          hidden_header_logo: true,
          next: 'recovery.phone.new'
        },
        component: () => import('@/components/photo/FaceRecognition.vue')
      },
    ]
  },
  {
    path: 'mypage/recovery/phone/mail',
    name: 'recovery.phone.mail',
    meta: {
      close_name: 'lp',
      next: 'recovery.phone.mail.store',
      action: 'recovery_pho_number'
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisteredAuthMail.vue')
    }
  },
  {
    path: 'mypage/recovery/phone/mail/store',
    name: 'recovery.phone.mail.store',
    meta: {
      from: {
        name: 'recovery.phone.mail',
        redirect: 'recovery.phone.mail'
      },
      next: 'recovery.phone.new',
      action: 'recovery_pho_number'
    },
    components: {
      upper: () => import('@/views/upper/RegisterMailCodeLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'mypage/recovery/phone/atmAddress',
    name: 'recovery.phone.atmAddress',
    meta: {
      next: 'recovery.phone.new',
      action: 'recovery_pho_number'
    },
    components: {
      upper: () => import('@/views/lower/CheckAtmAddress.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'auth/mail/:uuid',
    name: 'auth.mail.uuid',
    meta: {
      next: 'auth.mail.complete',
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/AuthMailLoading.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'auth/mail/complete',
    name: 'auth.mail.complete',
    meta: {
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/AuthMailComplete.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'authmail/update/:uuid',
    name: 'authmail.update.uuid',
    meta: {
      next: 'authmail.recept',
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/AuthmailUpdate.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'authmail/recept',
    name: 'authmail.recept',
    meta: {
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/AuthmailRecept.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'authmail/auth/:uuid',
    name: 'authmail.auth.uuid',
    meta: {
      public_path: true,
      allow_pc: true
    },
    components: {
      upper: () => import('@/views/upper/AuthmailAuth.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  }
] as RouteRecordRaw[];
