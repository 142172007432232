import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class HighResolution extends VuexModule {
  // 高解像度撮影が有効か
  _enabled: boolean = true;

  // 撮影ページに遷移済みか
  _photoStarted: boolean = false;

  // 撮影ページに遷移後、リロード済みか
  _refreshed: boolean = false;

  @Mutation
  reset() {
    this._enabled = true;
    this._photoStarted = false;
    this._refreshed = false;
  }

  @Mutation
  _setEnabled(value: boolean) {
    this._enabled = value;
  }

  @Mutation
  _setPhotoStarted(value: boolean) {
    this._photoStarted = value;
  }

  @Mutation
  _setRefreshed(value: boolean) {
    this._refreshed = value;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  get photoStarted(): boolean {
    return this._photoStarted;
  }

  get refreshed(): boolean {
    return this._refreshed;
  }
}
