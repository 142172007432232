import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class ConfirmCode extends VuexModule {
  _confirm_code: string | null = null;

  @Mutation
  reset() {
    this._confirm_code = null;
  }

  @Mutation
  _set(value: string) {
    this._confirm_code = value;
  }

  get confirm_code(): string | null {
    return this._confirm_code;
  }
}
