import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Processing extends VuexModule {
    /**
     * 処理中フラグ
     */
    _isApiProcessing: boolean = false;

    /**
     * 処理中フラグ取得
     */
    get isApiProcessing(): boolean {
      return this._isApiProcessing
    }

    @Mutation
    reset() {
      this._isApiProcessing = false;
    }

    /**
     * 処理中フラグ更新
     */
    @Mutation
    set(value: boolean) {
        this._isApiProcessing = value;
    }
}
