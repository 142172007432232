import { store } from '@/store';
import { IEntryPointApiName, IToken } from '@/types/token';

/**
 * AuthorizeAPIから遷移してきたか
 * @return { boolean }
 */
export const isAuthorize = (): boolean => {
  const token: IToken | null = store.getters['Auth/payload'];
  const entryPoint: IEntryPointApiName | undefined = token ? token.entry_point_api_name : undefined;
  return entryPoint === 'authorize';
};

/**
 * QR認証から遷移してきたか
 * @return { boolean }
 */
export const isQrAuthorize = (): boolean => {
  const token: IToken | null = store.getters['Auth/payload'];
  const entryPoint: IEntryPointApiName | undefined = token ? token.entry_point_api_name : undefined;
  return entryPoint === 'qrAuthorize';
};

/**
 * 顔認証から遷移してきたかの判定を行う
 * @return { boolean }
 */
export const isFaceAuthorize = (): boolean => {
  const token: IToken | null = store.getters['Auth/payload'];
  const entryPoint: IEntryPointApiName | undefined = token ? token.entry_point_api_name : undefined;
  return entryPoint === 'faceAuthorize';
};

/**
 * マイページから遷移してきたかの判定を行う
 * @return { boolean }
 */
export const isMyPage = (): boolean => {
  const token: IToken | null = store.getters['Auth/payload'];
  const entryPoint: IEntryPointApiName | undefined = token ? token.entry_point_api_name : undefined;
  return token == null || entryPoint === 'myPage';
};

/**
 * Expressモードが有効かどうかを返す
 * @return { boolean }
 */
export const isEnableExpress = (): boolean => {
  const token: IToken | null = store.getters['Auth/payload'];
  const enable_express: boolean | undefined = token ? token.enable_express : undefined;
  return enable_express || false;
};

/**
 * 7BK在留の登録か.
 * @return { boolean }
 */
export const isSevenBankResidence = (): boolean => {
  const token: IToken = store.getters['Auth/payload'] || {};
  return !!token.seven_bank_residence;
}
