import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';

@Module({ namespaced: true })
export default class Customizes extends VuexModule {
  _customizes: Object[] | null = null;

  @Mutation
  reset() {
    this._customizes = null;
  }

  @Mutation
  _set(value: Object[]) {
    this._customizes = value;
  }

  /**
   * 個社項目取得API実装
   *
   * @returns {Promise<void>}
   * @memberof Customizes
   */
  @Action({ rawError: true })
  async load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(API_DOMAIN + '/v1/customizes')
        .then((response: any) => {
          // 返ってきた個社項目をセット
          this.context.commit('_set', response.data.customizes);
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * 個社項目取得
   *
   * @readonly
   * @type {(Object[] | null)}
   * @memberof Customizes
   */
  get customizes(): Object[] | null {
    return this._customizes;
  }
}
