import { RouteRecordRaw } from 'vue-router';

/**
 * メールアドレス、電話番号認証後のルーティング設定
 */
export default [
  {
    path: 'registered/userInfo',
    name: 'registered.userinfo',
    components: {
      upper: () => import('@/views/upper/RegisteredUserInfo.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'registered/guide/flow',
    name: 'registered.guide.flow',
    meta: {
      enableBack: true,
      hidden_header_logo: true,
      breadcrumb: [0, 0, 0, 0]
    },
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisteredFlowGuide.vue')
    }
  }
] as RouteRecordRaw[];
