import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
import { IToken } from '@/types/token';
import { store } from '@/store';

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  /** 認証用のcode. */
  _code: string | null = null;
  /** JWS(Header.Payload(JWT).Signature). */
  _token: string | null = null;
  /** JWTの有効期限. */
  _expired: number | null = null;

  @Mutation
  reset() {
    this._code = null;
    this._token = null;
    this._expired = null;
    axios.defaults.headers.common['Authorization'] = '';
  }

  @Mutation
  _setCode(value: string) {
    this._code = value;
  }

  @Mutation
  _setToken(value: string) {
    this._token = value;
  }

  @Mutation
  _setExpired(payload: IToken | null) {
    if (payload && payload.exp && payload.iat) {
      // exp_sec: 有効期限までの残り秒数
      const exp_sec = payload.exp - payload.iat;
      this._expired = Math.round((new Date()).getTime() / 1000) + exp_sec;
    }
  }

  /**
   * 認証画面用トークン取得API実装
   *
   * @param {string} code
   * @returns {Promise<void>}
   * @memberof Auth
   */
  @Action({ rawError: true })
  login(code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!code && !this._code) {
        // 初回のトップページ表示でcodeがない場合は認証エラー
        reject(new Error('code_invalid'));
        return;
      } else if ((!code && this._code) || code === this._code) {
        // 2回目以降のトップページ表示でcodeがない場合(規約画面からの遷移等)、
        // またはcodeが同じ場合(トップページリロード)に通る
        // リロードで表示された場合のためにトークンを復帰する
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this._token;
        resolve();
        return;
      }

      store.dispatch('resetAll');
      const params = new URLSearchParams();
      params.append('code', code);
      axios
        .post(API_DOMAIN + '/v1/token', params)
        .then((response: any) => {
          // 返ってきたトークンをセット
          this.context.commit('_setCode', code);
          this.context.commit('_setToken', response.data.token);
          this.context.commit('_setExpired', this.payload);

          // デフォルトのリクエストヘッダーに Authorization を埋め込む
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * トークンAPIからトークンを生成してstoreに保持する
   *
   * @param {string} code
   * @returns {Promise<void>}
   * @memberof Auth
   */
  @Action({ rawError: true })
  createToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_DOMAIN}/v1/token`)
        .then((res: any) => {
          const token = res.data.token
          // vuex(storage)にトークンを保存する
          this.context.commit('_setToken', token);

          // デフォルトのリクエストヘッダーに Authorization を埋め込む
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * code(セッションIDとnonceを加工したもの)取得
   *
   * @readonly
   * @type {(string | null)}
   * @memberof Auth
   */
  get code(): string | null {
    return this._code;
  }

  /**
   * 認証画面用トークン取得
   *
   * @readonly
   * @type {(string | null)}
   * @memberof Auth
   */
  get token(): string | null {
    return this._token;
  }

  /**
   * トークン有効期限の取得
   * UTC 1970-01-01 00:00:00 からの経過秒数
   *
   * @readonly
   * @type {(number | null)}
   * @memberof Auth
   */
  get expired(): number | null {
    return this._expired;
  }

  /**
   * トークンからペイロードオブジェクトを取得
   *
   * @readonly
   * @type {{ IToken | null }}
   * @memberof Auth
   */
  get payload(): IToken | null {
    if (this._token == null || this._token === '') {
      return null;
    }
    const base64Url = this._token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const token: IToken = JSON.parse(decodeURIComponent(escape(window.atob(base64)))) as IToken;

    return token
  }

  /**
   * トークンを設定する
   *
   * @param token トークン
   */
  @Action({})
  setToken(token: string) {
    this.context.commit('_setToken', token);
    this.context.commit('_setExpired', this.payload);
  }
}
