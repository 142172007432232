<template>
  <div class="loader">
    <div class="loader__icon">
      <span v-for="i in 12" :key="i"></span>
    </div>
    <p class="loader__message"><slot></slot></p>
  </div>
</template>
<script lang="ts">
import { Vue } from 'vue-property-decorator';

export default class Loader extends Vue {}
</script>

<style scoped lang="scss">
@import '@/assets/scss/math';
@import '@/assets/scss/mixins/color';

$loader_icon_num: 12;
$loader_icon_size: 10px;
$loader_icon_radian: calc(360 / $loader_icon_num / 180 * pi());
$loader_radius: 30px;

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $basic-color;

  &__icon {
    position: relative;
    width: calc($loader_radius * 2);
    height: calc($loader_radius * 2);
    border-radius: 50%;
    span {
      position: absolute;
      display: block;
      width: $loader_icon_size;
      height: $loader_icon_size;
      margin-left: calc($loader_icon_size * -1 / 2);
      margin-top: calc($loader_icon_size * -1 / 2);
      background-color: $basic-color;
      border-radius: 50%;
      @for $i from 1 through $loader_icon_num {
        &:nth-child(#{$i}) {
          $radian: calc($loader_icon_radian * ($i - 1));
          left: calc(cos($radian) * $loader_radius + $loader_radius);
          top: calc(sin($radian) * $loader_radius + $loader_radius);
          animation: spin 1.2s infinite ease-in-out both;
          animation-delay: calc($i * 0.1s - 1.2s);
        }
      }
    }
  }

  &__message {
    font-size: 1rem;
    margin-top: 30px;
    text-align: center;
    white-space: pre-wrap;
  }
}

@keyframes spin {
  0%,
  80%,
  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
