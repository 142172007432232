import { PluginOptions, exception, pageview, set, event } from 'vue-gtag';
import { App } from 'vue'
import { IToken } from '@/types/token';
import { store } from '@/store';
import { GA_TRACKING_ID, OPT_CONTAINER_ID } from '@/plugins/getenv';

// vue-gtag オプション
export const VueGtagOptions: PluginOptions = {
  config: {
    id: GA_TRACKING_ID
  }
};

// pageviewをGAに送信する
export const pageView = (version: string, path: string) => {
  const token: IToken = store.getters['Auth/payload'];
  const companyId = store.getters['company/companyId'];
  const entryPointApiName = token ? token.entry_point_api_name : ''
  set({
    dimension1: version,
    dimension2: companyId,
    dimension3: entryPointApiName,
  });
  pageview({
    page_title: document.title,
    page_location: window.location.href,
    page_path: path
  });
}

// 例外をGAに送信する
export const autoExceptionTracking = (app: App) => {
  const oldErrorHandler = app.config.errorHandler;
  app.config.errorHandler = (error: any, vm: any, info: any) => {
    exception({ description: error.message });
    if (typeof oldErrorHandler === 'function') {
      oldErrorHandler.call(window, error, vm, info);
    }
  };
  window.addEventListener('error', error => {
    exception({ description: error.message });
  });
  window.addEventListener('unhandledrejection', (e) => {
    exception({ description: e.reason || '(unhandledrejection)' });
  });
}

// Google OptimizeのコンテナIDは環境変数から取得
const optimizeContainerId: string = OPT_CONTAINER_ID;

// アクティベーションイベントを発火する関数
const activateExperiment = () => {
  if (!optimizeContainerId || !(window.dataLayer instanceof Array)) {
    return;
  }
  window.dataLayer.push({ event: 'optimize.activate' });
};

// 非同期でDOM更新が行われるためアクティベーションイベント発火回数を間引く
const debounce = <T extends (...args: any[]) => unknown>(
  callback: T,
  delay = 120
): ((...args: Parameters<T>) => void) => {
  let timeoutId: any;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback(...args), delay);
  };
};
const debouncedActivateExperiment = debounce<(time: number) => void>(
  activateExperiment
);

// dataLayerオブジェクト
const containerIdSymbol = Symbol(optimizeContainerId);
type OptimizeData = {
  [containerIdSymbol]: boolean;
  start: number;
  end: (() => void) | null;
  timeout: number;
};

// dataLayer初期化
const initDataLayer = (hide: OptimizeData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.hide = hide;
};

// アンチフリッカー
const antiFlicker = (timeout: number) => {
  return new Promise<void>(resolve => {
    if (!optimizeContainerId) {
      resolve();
      return;
    }
    const hide: any = {
      [optimizeContainerId]: true,
      start: new Date().getTime(),
      end: resolve,
      timeout
    };
    initDataLayer(hide);
    setTimeout(function() {
      resolve();
      hide.end = null;
    }, timeout);
  });
};

// Promiseを返すのでスプラッシュ表示で待ち合わせると良い
export const experimentLoaded = antiFlicker(4000);

// 環境変数にコンテナIDがある場合のみGoogleOptimizeを有効にする
export const enableGoogleOptimize = (app: App) => {
  if (optimizeContainerId && VueGtagOptions.config !== undefined) {
    VueGtagOptions.config.params = {
      optimize_id: optimizeContainerId
    };
    app.mixin({
      updated() {
        // DOM更新時にアクティベーションイベント発火
        window.requestAnimationFrame(debouncedActivateExperiment);
      }
    });
  }
}

const eventWrapper = (action: string, eventParams: object) => {
  if (!VueGtagOptions.config || !VueGtagOptions.config.id) {
    return;
  }
  event(action, eventParams);
}

// エラー発生時にエラーコードをGAに送信
export const eventException = (errorCode: string) => {
  eventWrapper('proost', {
    event_category: 'エラー',
    event_label: errorCode
  });
}

// 電話番号認証成功時に会員IDをGAに送信
export const eventAuthorized = (memberId: number|undefined) => {
  eventWrapper('proost', {
    event_category: '電話番号認証成功',
    event_label: memberId
  });
}

// 言語選択イベントをGAに送信
export const eventSelectLanguage = (language: string) => {
  eventWrapper('proost', {
    event_category: '言語選択',
    event_label: language
  });
}

export default {
  install(app: App) {
    autoExceptionTracking(app);
    enableGoogleOptimize(app);
  },
};
