import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class DocumentType extends VuexModule {
  _documentType: string | null = null;

  @Mutation
  reset() {
    this._documentType = null;
  }

  @Mutation
  _set(value: string) {
    this._documentType = value;
  }

  get document_type(): string | null {
    return this._documentType;
  }
}
