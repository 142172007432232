import { Vue } from 'vue-property-decorator';
import { store } from '@/store';

export const toast = (message: string, vue: Vue) => {
  vue.$toast.clear();

  if (message) {
    vue.$toast.error(message, { position: 'top', duration: 5000 });

    // storeにエラーメッセージを設定してここまで引き渡すケースがあるためクリアする
    store.commit('errorMessage', null);
  }
}
