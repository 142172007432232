import { RouteRecordRaw } from 'vue-router';

/**
 * ユーザー情報登録関連のルーティング設定
 */
export default [
  {
    path: 'register/attr',
    name: 'register.user',
    components: {
      upper: () => import('@/views/upper/RegisterUserSignup.vue'),
      lower: () => import('@/views/lower/RegisterUserSignup.vue')
    }
  },
  {
    path: 'register/attr',
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterUser.vue')
    },
    children: [
      {
        path: 'input',
        name: 'register.user.create',
        meta: {
          close_name: 'register.user',
          showCompanyLogo: true,
          next: 'register.user.confirm'
        },
        components: {
          lower: () => import('@/components/RegisterUserForm.vue')
        }
      },
      {
        path: 'confirm',
        name: 'register.user.confirm',
        meta: {
          close_name: 'register.user',
          showCompanyLogo: true,
          next: 'register.complete',
          from: {
            name: 'register.user.create',
            redirect: 'register.user.create'
          }
        },
        components: {
          lower: () => import('@/components/RegisterUserConfirm.vue')
        }
      }
    ]
  },
  {
    path: 'register/complete',
    name: 'register.complete',
    meta: {
      showCompanyLogo: true,
    },
    components: {
      upper: () => import('@/views/upper/RegisterComplete.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  },
  {
    path: 'registered/attr',
    name: 'registered.user',
    components: {
      upper: () => import('@/views/upper/RegisterUserSignup.vue'),
      lower: () => import('@/views/lower/RegisteredUserSignup.vue')
    }
  },
  {
    path: 'registered/attr',
    components: {
      upper: () => import('@/components/Empty.vue'),
      lower: () => import('@/views/lower/RegisterUser.vue')
    },
    children: [
      {
        path: 'input',
        name: 'registered.user.edit',
        meta: {
          close_name: 'registered.user',
          closeModificationsInput: 'top',
          next: 'registered.user.confirm'
        },
        components: {
          lower: () => import('@/components/RegisterUserForm.vue')
        }
      },
      {
        path: 'confirm',
        name: 'registered.user.confirm',
        meta: {
          close_name: 'registered.user',
          closeModificationsInput: 'top',
          next: 'registered.complete',
          from: {
            name: 'registered.user.edit',
            redirect: 'registered.user.edit'
          }
        },
        components: {
          lower: () => import('@/components/RegisterUserConfirm.vue')
        }
      }
    ]
  },
  {
    path: 'registered/complete',
    name: 'registered.complete',
    components: {
      upper: () => import('@/views/upper/RegisteredComplete.vue'),
      lower: () => import('@/components/Empty.vue')
    }
  }
] as RouteRecordRaw[];
