import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';
import axios from 'axios';
import { API_DOMAIN } from '@/plugins/getenv';
export interface Modifications {
  fam_name?: number;
  fir_name?: number;
  fam_name_kana?: number;
  fir_name_kana?: number;
  bir_date?: number;
  gender?: number;
  pos_code?: number;
  pref?: number;
  city?: number;
  street?: number;
  building?: number;
  image?: number;
  doc_expire?: number;
  doc_number?: number;
}

@Module({ namespaced: true })
export default class ModificationsModule extends VuexModule {
  private _modifications: Modifications | null = null;
  private _modificationsList: string[] | null = null;

  @Mutation
  reset() {
    this._modifications = null;
    this._modificationsList = null;
  }

  /**
   * 修正依頼項目
   *
   * @param {Modifications} value
   * @memberof ModificationsModule
   */
  @Mutation
  public async SET_MODIFICATIONS(value: Modifications) {
    this._modifications = value;
  }

  /**
   * 修正依頼項目のリスト
   * @param value
   * @memberof ModificationsModule
   */
  @Mutation
  public async SET_MODIFICATIONS_LIST(payload: Modifications | null) {
    let modificationsList = [
      'fam_name', //
      'fir_name',
      'fam_name_kana',
      'fir_name_kana',
      'gender',
      'bir_date',
      'pos_code',
      'pref',
      'city',
      'street',
      'building',
      'image',
      'doc_expire',
      'doc_number'
    ];
    modificationsList = modificationsList.filter(element => {
      if (payload) {
        const key: string = element;
        if (key in payload) {
          return (payload as any)[key] === 1;
        }
        return false;
      }
      return false;
    });

    this._modificationsList = modificationsList
  }

  /**
   * 修正依頼項目を削除
   *
   * @memberof ModificationsModule
   */
  @Mutation
  public RSET_MODIFICATIONS() {
    this._modifications = null;
  }

  /**
   * APIから修正依頼項目を取得
   *
   * @returns {Promise<Modifications>}
   * @memberof ModificationsModule
   */
  @Action({ rawError: true })
  async load(): Promise<Modifications> {
    return new Promise((resolve, reject) => {
      axios
        .get(API_DOMAIN + '/v1/modifications')
        .then(async (response) => {
          const modifications: Modifications = response.data;
          if (modifications) {
            let isMatch = false;
            for (const key in modifications) {
              if ((modifications as any)[key] === 1) {
                isMatch = true;
                break;
              }
            }
            // 1が含まれていたら修正依頼項目を保存
            if (isMatch) {
              this.context.commit("SET_MODIFICATIONS", modifications);
            } else {
              this.context.commit("RSET_MODIFICATIONS");
            }
          }
          this.context.commit('SET_MODIFICATIONS_LIST', modifications);
          resolve(modifications);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * 修正依頼項目を取得
   *
   * @readonly
   * @type {Modifications | null}
   * @memberof ModificationsModule
   */
  public get modifications(): Modifications | null {
    return this._modifications;
  }

  /**
   * 修正依頼項目をリスト化するための配列を取得
   *
   * @readonly
   * @type {string[]}
   * @memberof ModificationsModule
   */
  public get modificationsList(): string[] {
    return this._modificationsList || []
  }
}
